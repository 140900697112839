* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

html,
body {
  height: 100%;
  background: #212121;
}

.menu {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: rgba(26, 26, 26, 0.8); /* Semi-transparent background */
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Ensure the menu is above other content */
}

.menu a {
  font-size: 14px;
  border-radius: 5px;
  text-decoration: none;
  padding: 10px 20px;
  color: #fff;
  background: #333;
  text-transform: uppercase;
  margin: 0 10px;
  letter-spacing: 1px;
  text-align: center;
  transition: 0.3s ease;
}

.menu a:hover {
  background: #9354bf;
  transform: scale(1.1);
}

.content {
  padding-top: 5rem; /* Add padding to avoid content being hidden behind the fixed menu */
  text-align: center;
  color: white;
}