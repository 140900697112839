@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(135deg, #1e1e1e, #2c2c2c);
  color: white;
  margin: 0;
  padding: 0;
}

.about-me {
  padding: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.profile-photo {
  max-width: 150px;
  border-radius: 50%;
  margin-bottom: 1rem;
  object-fit: cover; /* Ensures the entire image is visible without being cropped */
  border: 1px solid #9354bf;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds a subtle box shadow */
  background-color: #fff; /* Adds a background color to ensure the image is not transparent */
}

.description {
  max-width: 600px;
  text-align: left;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.description h1 {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.description p {
  margin-bottom: 1.5rem; /* Increased margin for better readability */
  line-height: 1.6; /* Improved line spacing */
  font-size: 1.1rem; /* Increased font size for better readability */
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center; /* Center the contact container */
}

.contact-details {
  text-align: left;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  align-self: center; /* Center the contact details */
}

.contact-details h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.contact-details p {
  margin-bottom: 1rem;
  font-size: 1.1rem; /* Increased font size for better readability */
}

.contact-details a {
  color: #9354bf;
  text-decoration: none;
}

.contact-details a:hover {
  text-decoration: underline;
}

/* Media Queries for Mobile Devices */
@media (max-width: 600px) {
  .profile {
    flex-direction: column;
    align-items: center;
  }

  .profile-photo {
    order: -1; /* Move the photo to the top */
    max-width: 100px;
  }

  .description {
    margin-right: 0;
    margin-bottom: 2rem;
    text-align: center;
  }

  .description h1 {
    font-size: 1.5rem;
  }

  .description p {
    font-size: 1rem;
  }

  .contact-details h2 {
    font-size: 1.2rem;
  }

  .contact-details p {
    font-size: 1rem;
  }
}