@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  background: #121212;
  color: #e0e0e0;
  display: flex;
  flex-direction: column;
}

.home {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 2rem;
  max-width: 80%;
  margin: 0 auto;
}

.home .post-date {
  font-size: 1em;
  color: #9354bf;
  text-align: right;
  margin-top: 5px;
}

.post {
  position: relative; /* Ensure the post container is positioned relative */
  background: #1e1e1e;
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.post:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
}

.post h1 {
  font-size: 2.2rem; /* Increased font size */
  color: #9354c0; /* Royal purple color */
  font-weight: 700;
  text-transform: uppercase; /* Uppercase letters */
  letter-spacing: 1px; /* Increased letter spacing */
  border-bottom: 2px solid #9354bf; /* Bottom border */
  padding-bottom: 0.1rem; /* Padding below the text */
}

.post h1 a {
  color: #9354c0; /* Royal purple color */
  text-decoration: none; /* Remove underline */
}

.post h1 a:hover {
  color: #a787be; /* Slightly lighter purple on hover */
  text-decoration: underline; /* Add underline on hover */
}

.post h2 {
  margin-bottom: 1rem;
  color: #a787be; /* Royal purple color */
}

.post h3 {
  margin-bottom: 1rem;
  color: #a787be; /* Royal purple color */
  text-align: start;
}

.post p {
  font-family: 'Open Sans', sans-serif; /* Clean and modern font */
  font-size: 1.2rem;
  line-height: 1.4;
  color: #e0e0e0;
  margin-bottom: 1.5rem;
}

.post pre {
  background: #2e2e2e;
  border-radius: 5px;
  overflow-x: auto;
  text-align: left; /* Align text to the left */
  display: inline-block; /* Change to inline-block to fit content */
  white-space: pre-wrap; /* Wrap long lines */
  word-wrap: break-word; /* Break long words */
  margin-bottom: 20px;
  font-size: 1rem; /* Increased font size */
  line-height: 1.6; /* Increased line height */
  border: 1px solid #444; /* Add border */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add box-shadow */
  padding: 0.8rem; /* Adjust padding */
}

.post pre:hover {
  background: #3a3a3a; /* Slightly lighter background on hover */
  border-color: #555; /* Subtle border color change on hover */
}

.post code:hover {
  background: #3a3a3a; /* Slightly lighter background on hover */
  border-color: #555; /* Subtle border color change on hover */
}

.post code {
  font-family: 'Source Code Pro', monospace;
  color: #dcdcdc;
  border-radius: 3px; /* Add border-radius */
  display: inline-block; /* Change to inline-block to fit content */
  text-align: left; /* Align text to the left */
  margin: 0 auto; /* Center the block with even space around it */
  max-width: 100%; /* Optional: Limit the width of the code block */
}

.end-of-article {
  margin-top: 20px;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  position: relative;
}

.artistic-pattern {
  width: 100%;
  height: 10px;
  background: url('../../assets/images/article-end.svg') no-repeat center center;
}

.mvb-text {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 1.4rem;
  color: #9354bf; /* Royal purple color */
  text-align: center;
  font-family: 'Dancing Script', cursive; /* Signature-like font */
}

pre {
  position: relative;
  z-index: 1; /* Ensure pre has a lower z-index */
}

.copy-btn {
  background: #444;
  border: none;
  color: #fff;
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: none;
  z-index: 2; /* Ensure button has a higher z-index */
}

.copy-btn .clipboard-icon {
  content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij4KICA8cGF0aCBkPSJNMTAgMkg4VjBINHYyaC0yYy0xLjEwOSAwLTEuOTk5Ljg5MS0xLjk5OSAxLjk5OXY4LjAwMWMwIDEuMTA4Ljg5IDEuOTk5IDEuOTk5IDEuOTk5aDguMDAxYzEuMTA4IDAgMS45OTktLjg5MSAxLjk5OS0xLjk5OVY0YzAtMS4xMDktLjg5MS0xLjk5OS0xLjk5OS0xLjk5OXpNMTIuOTk5IDEyLjAwMWMwIC4xMTEtLjg5LjE5OTktMS45OTkuMTk5OUg0Yy0xLjExIDAtMi0uODg5LTIuMDAxLTEuOTk5VjRjMC0uMTEgLjg5LTEuOTk5IDEuOTk5LTEuOTk5aDJ2MWgyVjJINWMuNTUyIDAgMSAuNDQ4IDEgMWg0Yy41NTIgMCAxLS40NDggMS0xaDJjLjU1MiAwIDEgLjQ0OCAxIDF2OC4wMDF6bS0yLTloLTZ2MWg2di0xeiIvPgo8L3N2Zz4=');
}

pre:hover .copy-btn,
.copy-btn:focus {
  display: block;
}

pre.copied::after {
  content: 'Copied';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(206, 92, 200, 0.8); /* Semi-transparent background */
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 1.5rem;
  animation: scaleFadeOut 2s forwards;
  z-index: 3; /* Ensure message has the highest z-index */
}

@keyframes scaleFadeOut {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
  }
}

.post-subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.post-date {
  font-size: 1em;
  color: #9354bf;
}

.share-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.share-btn svg {
  margin-top: 5px;
  width: 20px;
  height: 20px;
  stroke: #9354c0; /* Same purple as the title */
}

.share-btn:hover svg {
  stroke: #a787be; /* Slightly lighter purple on hover */
}

/* Media Queries for Mobile Devices */
@media (max-width: 600px) {
  .home {
    padding: 0.2rem; /* Reduce padding */
    max-width: 90%;
  }

  .post {
    padding: 1rem; /* Reduce padding */
  }

  .post h1 {
    font-size: 1.8rem;
  }

  .post p {
    font-size: 1rem;
  }

  .post pre {
    padding: 0.8rem; /* Adjust padding */
    font-size: 0.9rem; /* Adjust font size */
    line-height: 1.4; /* Adjust line height */
    margin-bottom: 20px;
    display: inline-block; /* Change to inline-block to fit content */
    max-width: 100%; /* Ensure it doesn't exceed the container width */
  }

  .post code {
    max-width: 100%; /* Optional: Limit the width of the code block */
    display: inline-block; /* Change to inline-block to fit content */
  }

  .copy-btn {
    display: block;
  }
}