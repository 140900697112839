@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  background: #121212;
  color: #333;
  margin: 0;
  padding: 0;
}

.theo-container {
  padding: 2rem;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.theo-container p {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.spiderman-photo {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Media Queries for Mobile Devices */
@media (max-width: 600px) {
  .theo-container {
    padding: 1rem;
  }

  .theo-container p {
    font-size: 1.2rem;
  }

  .spiderman-photo {
    max-width: 90%;
  }
}